import React from "react";

import "../../utils/layout.css";
import "../../utils/constants.css";

const PrivacyPolicy = (props) => {
  return (
    <div className="flex-column-start-start" style={{width: '100vw', padding:'20px'}}>
      <h1>Privacy policy</h1>
     <p>This Privacy Policy (the &ldquo;<strong>Policy</strong>&rdquo;) was updated on 7 July 2021. Please review this Policy to note the changes.</p>
<p>This Policy applies to the collection, use and disclosure of an individual customer&rsquo;s Personal Data (hereinafter defined) arising from goods and/or services offered by Steady (collectively referred to as &ldquo;<strong>Steady</strong>&rdquo;).</p>
<p><strong>1. General</strong></p>
<p>1.1 This Policy statement provides information on the obligations and policies of Steady in respect of a user&rsquo;s Personal Data, whereby the user may be browsing the Steady website without registering for an account, as a registered user, or as a health care provider. Steady undertakes to use reasonable efforts in applying, where practicable, those principles and the processes set out herein to its operations.</p>
<p>1.2 Steady&rsquo;s officers, management, and members of staff shall use reasonable endeavours to respect the confidentiality of and keep safe any and all Personal Data collected and/or stored and/or disclosed and/or used for, or on behalf of, Steady. Steady shall use reasonable endeavours to ensure that all collection and/or storage and/or disclosure and/or usage of Personal Data by Steady shall be done in an appropriate manner and in accordance with the Act and this Policy.</p>
<p>1.3 By interacting with us, submitting information to us, or signing up for any products or services offered by us, you agree and consent to Steady as well as to its respective representatives and/or agents ("Representatives") (collectively referred to herein as "Steady", "<strong>us</strong>", "<strong>we</strong>" or "<strong>our</strong>") collecting, using, disclosing and sharing amongst themselves your Personal Data, and disclosing such Personal Data to Steady&rsquo;s authorised service providers and relevant third parties in the manner set forth in this Policy.</p>
<p>1.4 This Policy supplements but does not supersede nor replace any other consents you may have previously provided to us in respect of your Personal Data, and your consents herein are additional to any rights which we may have at law to collect, use or disclose your Personal Data.</p>
<p>1.5 For the purposes of this Policy, in line with the provisions under the Singapore Personal Data Protection Act 2012 (No. 26 of 2012) (the &ldquo;Act&rdquo;), &ldquo;Personal Data&rdquo; shall mean data, whether true or not, about an individual customer who can be identified &mdash; from that data; or from that data and other information which an organisation has or is likely to have access. Such Personal Data shall also refer to that which is already in the possession of Steady or that which shall be collected by Steady in future.</p>
<p><strong>2. Contacting the Data Protection Officer</strong></p>
<p>2.1 Where you legitimately request access to and/or correction of Personal Data relating to you, such Personal Data which is in the possession and control of Steady, Steady shall provide and/or correct that data within thirty (30) days and in a manner in accordance with its standard procedures as stated hereinafter.</p>
<p>2.2 In accordance with the Act, Steady has established a process for receiving and responding to any query or complaint that may arise with respect to the application of this Act. To ensure that Steady receives your complaints and enquiries, please send the same via email to the Data Protection Officer (the &ldquo;DPO&rdquo;) of Steady at the following email address: <a href="undefined" target="_blank" rel="noopener">info@dxd.com.sg</a></p>
<p>2.3 Please note that if your Personal Data has been provided to us by a third party (e.g. a member via a referral process), you should contact that individual to make such queries, complaints, and access and correction requests to Steady on your behalf.</p>
<p>2.4 Should you not wish Steady to use your Personal Data for any of the purposes listed in Clauses 3.2 to 3.4, or not to receive promotional materials from Steady, you may opt out by sending a clearly worded email to the DPO via the email address provided in Clause 2.2.&nbsp;</p>
<p>Your request shall be processed within thirty (30) days. Please note however that this may affect our ability to attend to your needs in the event where there is already an existing business relationship.</p>
<p><strong>3. Statement of Practices</strong></p>
<p>Types of Personal Data Collected</p>
<p>3.1 As part of its day-to-day activity, Steady may collect from you, through various means, including via our websites, smart phone applications and any other forms used by Steady from time to time, some or all of the following Personal Data:-</p>
<ul>
<li>First and last name;</li>
<li>Postal Address;</li>
<li>Phone number (mobile and/or home number);</li>
<li>Office number;</li>
<li>Fax number;</li>
<li>Email address;</li>
<li>Bank account/credit card details;</li>
<li>Gender;</li>
<li>Personal Data of your emergency contacts;</li>
<li>Previous and present employment;</li>
<li>Education;</li>
<li>IP addresses; and</li>
<li>Photographs and images;</li>
</ul>
<p>Cookies</p>
<p>We use cookies on our websites to track website visitorship and experience. Most web browsers are designed to accept cookies. If you do not wish to receive any cookies, you may set your browser to refuse it.</p>
<p>Purpose of Collection of Personal Data</p>
<p>3.2 The above Personal Data mentioned in Clause 3.1 is collected for the purposes of:-</p>
<ul>
<li>Tracking and monitoring the usage of our website;</li>
<li>Generating user information which will help to improve the features and functionality of our website, or creating new features, functionalities and services available on our website;</li>
<li>Providing customer support for you;</li>
<li>Providing notices and/or contacting you via email or your user account in relation to your user account, regarding updates to our Terms and Conditions, this Policy, changes to your user account, or for other customer service purposes;</li>
<li>Informing you of new developments and services of other third parties which we are associated with;</li>
<li>Connecting you to other users and/or health care providers on our website which may be of relevance and/or interest to you;</li>
<li>Generating user information which will help to improve the design and quality of our website;</li>
<li>Investigating illegal activities, if any, being carried out on our website that violates our Terms and Conditions;</li>
<li>Detecting, addressing and/or preventing technical issues which may arise on our website;</li>
<li>Customising advertising which you may see when using our website and/or third party websites;</li>
<li>Identification purposes;</li>
<li>Conducting market research and client surveys to enhance the provision of our services; and</li>
<li>Measuring the performance of marketing campaigns carried out by us and providing you with marketing from third parties, if any.</li>
</ul>
<p><strong>Disclosure of Personal Data</strong></p>
<p>3.3 In order to carry out the functions described above, Steady may, from time to time, disclose your Personal Data between Steady&rsquo;s companies.</p>
<p>3.4 Without derogating from any of the above, Steady&nbsp; may also disclose your Personal Data to the following third parties:-</p>
<ul>
<li>Regulators and law enforcement officials;</li>
<li>Lawyers;</li>
<li>Auditors;</li>
<li>Third party service providers and consultants;</li>
<li>Credit, debit and charge card companies, banks and other entities processing payment;</li>
<li>Potential buyers or investors of Steady or any of Steady&rsquo;s companies; and</li>
<li>Any agent or sub-contractor acting on Steady&rsquo;s behalf for the provision of Steady&rsquo;s services.</li>
</ul>
<p>3.5 Steady may disclose your Personal Data to the abovementioned parties also in the occurrence of any of the following events:-</p>
<ul>
<li>To the extent that Steady is required to do so by the law;</li>
<li>In connection with any legal proceedings or prospective legal proceedings;</li>
<li>To establish, exercise or defendSteady&rsquo;s legal rights;</li>
<li>To the purchaser (or prospective purchaser) of any business or asset which Steady is (or is contemplating) selling;</li>
<li>To any person and/or entity for the purpose of processing such information on Steady&rsquo;s behalf;</li>
<li>To third parties who provide services to Steady or on its behalf;</li>
<li>To third parties who Steady provides services to;</li>
<li>To any third party that purchases Steady or Steady&rsquo;s business or any part of Steady or Steady&rsquo;s business;</li>
<li>With your consent; and</li>
<li>For the purposes of disaster recovery.</li>
</ul>
<p>Optional Provision of Personal Data</p>
<p>3.6 In some instances, you may also be requested to provide certain Personal Data that may be used to further improve Steady&rsquo;s services and/or better tailor the type of information presented to you. In most cases, this type of data is optional although, where the requested service is a personalised service dependent on your providing all requested data, failure to provide the requested data may prevent Steady from providing the service to you. This type of data includes, but is not limited to:-</p>
<ul>
<li>Your first and last name;</li>
<li>Your age;</li>
<li>Your gender;</li>
<li>Your race;</li>
<li>Your geographic location;</li>
<li>Your telephone number;</li>
<li>Your email address;</li>
<li>Particulars of next of kin;</li>
<li>Employment details;</li>
<li>Present/ past employment history;</li>
<li>Hobbies and leisure activities; and</li>
<li>Other related products and services subscribed to.</li>
</ul>
<p>3.7 Under certain circumstances, telephone calls made to any of Steady&rsquo;s companies to order and/or service hotlines and/or inquiry telephone numbers are recorded for the purposes of quality control, appraisal, as well as staff management and development. In such an event, by agreeing to this Policy, you hereby give your consent for the collection, use and disclosure of such Personal Data for the purposes of our records, following up with your enquiry and/or transaction, and for quality control and training purposes.</p>
<p><strong>4. Transfer of Personal Data Overseas</strong></p>
<p>Your Personal Data may be processed by Steady, its affiliates, agents and third parties providing services to Steady, in jurisdictions outside of Singapore. In this event, Steady will comply with the terms of the Act.</p>
<p><strong>5. Accuracy of Personal Data</strong></p>
<p>Where possible, Steady will validate data provided using generally accepted practices and guidelines. This includes the use of check sum verification on some numeric fields such as account numbers or credit card numbers. In some instances, Steady is able to validate the data provided against pre-existing data held by Steady. In some cases, Steady is required to see original documentation before we may use the Personal Data such as with Personal Identifiers and/or proof of address. To assist in ensuring the accuracy of your Personal Data in the possession of Steady, please inform us of any updates of any parts of your Personal Data by sending a clearly worded email to the DPO at the email address provided at Section 2.2.</p>
<p><strong>6. Protection of Personal Data</strong></p>
<p>Steady uses commercially reasonable physical, managerial, and technical safeguards to preserve the integrity and security of your Personal Data and will not knowingly allow access to this data to anyone outside Steady, other than to you or as described in this Policy. However, Steady cannot ensure or warrant the security of any information you transmit to Steady and you do so entirely at your own risk. In particular, Steady does not warrant that such information may not be accessed, altered, collected, copied, destroyed, disposed of, disclosed or modified by breach of any of Steady&rsquo;s physical, technical, or managerial safeguards.</p>
<p><strong>7. Access and Correction of Personal Data</strong></p>
<p>7.1 In accordance with Clause 2.1 of this Policy, you have the right to:-</p>
<p>a. check whether Steady holds any Personal Data relating to you and, if so, obtain copies of such data; and</p>
<p>b. require Steady to correct any Personal Data relating to you which is inaccurate for the purpose for which it is being used.</p>
<p>7.2 Steady reserves the right to charge a reasonable administrative fee in order to meet your requests under Clause 7.1(a). Upon payment of the requisite fee under Clause 7.1(a) and/or receipt of your request under Clause 7.1(b), your request shall be processed within thirty (30) days.</p>
<p>7.3 If you wish to verify the details you have submitted to Steady&nbsp; or if you wish to check on the manner in which Steady&nbsp; uses and processes your data, Steady &rsquo;s security procedures means that Steady&nbsp; may request for proof of identity before we reveal information. This proof of identity will take the form of full details of your name, the last 4 characters of your NRIC or Passport or FIN number. You must therefore keep this information safe as you will be responsible for any action which Steady&nbsp; takes in response to a request from someone using the aforementioned details.</p>
<p><strong>8. Storage and Retention of Personal Data</strong></p>
<p>Steady&nbsp; will delete, as reasonably possible, or otherwise anonymise any Personal Data in the event that the Personal Data is not required for any reasonable business or legal purposes of Steady&nbsp; and where the Personal Data is deleted from Steady &rsquo;s electronic, manual, and other filing systems in accordance with Steady &rsquo;s internal procedures and/or other agreements.</p>
<p><strong>9. Contacting you</strong></p>
<p>To the extent that any of the communication means which you have provided Steady with (which may include, your telephone number and fax number) is/will be listed on the Do Not Call Registry (the &ldquo;DNC&rdquo;), by checking the applicable box, or by any other means of indication, you hereby grant Steady&nbsp; your clear and unambiguous consent to contact you using all of your communication means you have provided to Steady&nbsp; including using via voice calls, SMS, WhatsApp, MMS, fax or other similar communications applications or methods, for the purposes as stated above in Paragraph 3.2. This will ensure your continued enjoyment of Steady&rsquo;s services.</p>
<p><strong>10. Change Policy</strong></p>
<p>Steady&nbsp; reserves the right to alter any of the clauses contained herein in compliance with local legislation, and for any other purpose deemed reasonably necessary by Steady. You should look at these terms regularly. If you do not agree to the modified terms, you should inform us as soon as possible of the terms to which you do not consent. Pending such notice, if there is any inconsistency between these terms and the additional terms, the additional terms will prevail to the extent of the inconsistency.</p>
<p><strong>11. Governing Law</strong></p>
<p>This Policy is governed by and shall be construed in accordance with the laws of Singapore. You hereby submit to the non-exclusive jurisdiction of the Singapore courts.</p>
<p><strong>12. Miscellaneous</strong></p>
<p>12.1 This Policy only applies to the collection and use of Personal Data by Steady. It does not cover third party sites to which we provide links, even if such sites are co-branded with our logo. Steady does not share your Personal Data with third party websites. Steady is not responsible for the privacy and conduct practices of these third party websites, so you should read their own privacy policies before disclosure of any Personal Data to these websites.</p>
<p>12.2 Steady&nbsp; will not sell your personal information to any third party without your permission, but we cannot be responsible or held liable for the actions of third party sites which you may have linked or been directed to Steady&rsquo;s website.</p>
<p>12.3 Steady&rsquo;s websites do not target and are not intended to attract children under the age of 18 years old.Steady does not knowingly solicit, collect or maintain personal information from children under the age of 18 years old or send them requests for personal data. If you are under the age of 18 years old, please cease your access and usage of our website at any time or manner.</p>
    </div>
  );
};

export default PrivacyPolicy;
