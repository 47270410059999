import React from "react";

import "../../utils/layout.css";
import "../../utils/constants.css";

const PrivacyPolicy = (props) => {
  return (
    <div className="flex-column-start-start" style={{width: '100vw', padding:'20px'}}>
        <h1>Terms Of Use</h1>
        <p><strong>DO NOT USE THIS SERVICE FOR EMERGENCY MEDICAL NEEDS. IF YOU EXPERIENCE A MEDICAL EMERGENCY, CALL 995 IMMEDIATELY.</strong></p>
<p><strong>IF YOU ARE THINKING ABOUT SUICIDE OR IF YOU ARE CONSIDERING TAKING ACTIONS THAT MAY CAUSE HARM TO YOU OR TO OTHERS OR IF YOU FEEL THAT YOU OR ANY OTHER PERSON MAY BE IN ANY DANGER OR IF YOU HAVE ANY MEDICAL EMERGENCY, YOU MUST IMMEDIATELY CALL THE EMERGENCY SERVICE NUMBER AND NOTIFY THE RELEVANT AUTHORITIES. THE STEADY SITE DISPLAYS THE SUICIDE HOTLINE IN SEVERAL PROMINENT LOCATIONS AND HAS AN INDEX OF NATIONAL RESOURCES FOR MENTAL HEALTH EMERGENCIES WHICH WE IMPLORE ALL USERS OF THIS SITE TO USE WHEN FACED WITH AN IMMEDIATE OR EMERGENT CRISIS.</strong></p>
<p>&zwj;</p>
<p>Please read these Terms of Use carefully ("Terms"). These Terms govern and apply to your access and use of the communication platform offered by heysteady.com (d/b/a &ldquo;Steady&rdquo;), Steady services available through the Steady website and Steady mobile apps (&ldquo;App&rdquo;) (all hereinafter collectively,&ldquo; Steady&rdquo; and the Steady "Service(s)") and any information, text, graphics, photos or other materials uploaded, downloaded or appearing on the Service (collectively referred to as "Content"). By accessing or using the Service, you agree to comply with and to be bound by all of the terms and conditions described in these Terms. If you do not agree to all of these terms and conditions, you are not authorized to use the Service. The terms &ldquo;You", "Your,&rdquo; and &ldquo;Yourself&rdquo; refer to the individual user of the Service.</p>
<p>You hereby certify that you are (i) over the age of eighteen (18) or have the legal ability to consent to Therapy or have your Parent/Guardian consent to Therapy, and (ii) physically located or are a resident of the State or Country you have chosen as your current residency when creating your Account. You agree to provide &ldquo;Contact Information&rdquo; (your personal contact and/or a close family member/relation) to your Provider to act as an information source to be used in case of a mental health crisis or other emergency where knowledge of your location is crucial. You acknowledge that your ability to access and use the Service is conditioned upon the truthfulness of the information you provide regarding your age, residence and Contact Information and that the Therapists you access (&ldquo;Provider(s)&rdquo;) are relying upon this certification in order to interact with you and provide the Services.</p>
<h2>Site Content</h2>
<p>Other than the guidance and advice you receive directly by you from your licensed Provider, the other educational, graphics, research sources and other incidental information on the Site, the Content, should not be considered medical advice. You should always talk to an appropriately qualified health care professional for diagnosis and treatment, including information regarding which medications or treatment may be appropriate for you. None of the Content represents or warrants that any particular medication or treatment is safe, appropriate, or effective for you. Steady does not endorse any specific tests, medications, products or procedures.</p>
<h2>Account Enrollment</h2>
<p>To access the Services, you must first enroll to establish an individual user account (&ldquo;Account&rdquo;), by providing certain information. You agree that you will not create more than one Account, or create an Account for anyone other than yourself without first receiving permission from the other person. In exchange for your use of the Service and, if applicable, in order for providers to send notices to you, you agree to: (i)provide true, accurate, current and complete information about yourself as prompted by our Account enrollment form (understanding you may use an anonymous user name or nickname after you provide certain personal and emergency Contact Information); and (ii) each time you log on, maintain and promptly update such Account information to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or Steady has reasonable grounds to suspect that such Account information is untrue, inaccurate, not current or incomplete, Steady reserves the right to transition your treatment to another Provider outside of Steady and refuse any future request(s) to use the Steady Services. You shall at all times remain responsible for maintaining the confidentiality of your Account password and username (if any) and any other security information related to your Account. Steady will not be liable for any loss that you incur as a result of someone else accessing and using your Account, either with or without your knowledge.</p>
<h2>Acceptable Use</h2>
<p>You agree not to access or use the Service in an unlawful way or for an unlawful or illegitimate purpose or in any manner that contravenes these terms. You shall not post, use, store or transmit(i) a message or information under a false name; (ii) information that is unlawful, libelous, defamatory, obscene, fraudulent, predatory of minors, harassing, threatening or hateful to any person; or (iii) information that infringes or violates any of the intellectual property rights of others or the privacy or publicity rights of others. You shall not attempt to disrupt the operation of the Service by any method, including through use of viruses, Trojan horses, worms, time bombs, denial of service attacks, flooding or spamming. You shall not use the Service in any manner that could damage, disable or impair the Service. You shall not attempt to gain unauthorized access to any user accounts or computer systems or networks, through hacking, password mining or any other means. You shall not use any robot, scraper or other means to access the Service for any purpose. Neither Steady nor its affiliates are under any obligation to respond to messages posted on the Service. You are solely responsible for the information or material you post on the Service.</p>
<p>&zwj;The following terms apply to any App accessed through or downloaded from any source (such as the Apple App Store or other similar platforms) where the App is made available (each such distribution platform an "App Provider"). You acknowledge and agree that:</p>
<p>&zwj;these Terms are between you and Steady, and not with the App Provider, and that Steady (not the App Provider), is solely responsible for the App; and the App Provider has no obligation to furnish any maintenance and support services with respect to the App claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Steady.</p>
<p>&zwj;</p>
<p>If you download the App, such as a stand-alone software product or a browser plugin, you agree that from time to time, the software may require that you download upgrades, updates and additional features we publish in order to improve, enhance, and further develop the software, Service, or App. The Service may change from time to time and/or Steady may stop (permanently or temporarily) providing the Service (or features within the Service), possibly without prior notice to you.</p>
<h2>Payment</h2>
<p>Any payment you make for the use of the Services is used to compensate Steady for its software development, overhead, administrative services and other corporate costs and fees, including transaction fees for credit card usage.</p>
<p>&zwj;The therapists practicing through the Platform also compensate for the use of the Steady technology and for administrative fees. Part of your payment for the use of the Services, accounted for separately, is remitted to the Provider for the direct clinical services provided to you. Steady does not participate, split, or take a percentage of this payment to the Provider. Regardless of any payments made, Steady does not hold itself out as your direct provider of therapy services as that is the role of your licensed, network accredited Provider. You agree to pay all fees and charges associated with your Account on a timely basis in accordance with the fee schedule, and the terms and rates published by Steady from time to time. Such fees and charges (including any taxes and late fees, as applicable) may be charged to the credit card you provided during the initial setup of your Account. You agree to maintain valid credit card information in your Account. By providing Steady with credit card information you authorize Steady to bill and charge your credit card.</p>
<h2>Therapists</h2>
<p>Steady does not directly employ the Therapists matched through the Service. Steady created a modern, digital health network of nationwide digitally trained and accredited Therapists.</p>
<p>In order to receive approval to practice using Steady, the applying Therapist must be found to be in compliance with the policies and procedures of Steady and must receive written approval from Steady. This verification process includes, but is not limited to:</p>
<p>&zwj;Verification of licensure. The practitioner holds a valid, current license to practice which is verified directly from the state license agency.</p>
<p>Verification of proper education and degree.</p>
<p>Verification of a minimum of five years work history or relevant work experience and confirmation that the Therapist can practice independently.</p>
<p>Verification of any applicable specialized training.&nbsp;</p>
<p>Your relationship with the Therapist is strictly with the Therapist. Steady is not involved in the Therapist relationship and does not interfere, validate or control the Therapist&rsquo;s treatment unless advocating on behalf of You when following up on a particular complaint. All that stated, You are always advised to exercise a high level of care and caution in the use of Steady as you would making any mental health or medical decision. You should never disregard, avoid, or delay obtaining medical advice from your primary doctor or other qualified healthcare provider or by using or combining Steady with traditional face-to-face appointments; solely because of information you saw on the Steady site or advice you received through a Therapist. Steady supports a holistic approach to mental health and the choices You have to create the best therapeutic journey that matches your individual needs.</p>
<h2>Intellectual Property</h2>
<p>All of the content available on or through the Service is the property of Steady or its licensors and is protected by copyright, trademark, patent, trade secret and other intellectual property law. You agree not to reproduce, retransmit, distribute, disseminate, sell, publish, broadcast, or circulate the content received through the Service to any third party. All software and accompanying documentation made available for download from the Service is the copyrighted work of Steady or its licensors. Any copy made of information obtained through the Service must include all applicable copyright notices.</p>
<p>"Steady" is a copyrighted term. All rights reserved. Subject to the terms of this Agreement, Steady hereby grants you a limited, revocable, non transferable and non-exclusive license to use the software, network facilities, content and documentation on and in the Service to the extent, and only to the extent, necessary to access and use the Service.</p>
<p>The license granted herein does not permit you to, and you agree not to: (i) modify, translate, reverse engineer, disassemble, decompile or create derivative works of the Service or allow a third party, whether directly or indirectly (including, but not limited to the direct or indirect use of wizards, agents, bots, or other utilities), to modify, translate, reverse engineer, disassemble, decompile or create derivative works of the Service; or (ii) transfer, distribute, sell, lease, rent, disclose or provide access to the Service to any third party or use the Service to provide service bureau, time sharing or other services to third parties.</p>
<h2>Disclaimers</h2>
<p>ACCESS TO THE SERVICE AND THE INFORMATION CONTAINED THEREIN IS PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, STEADY DISCLAIMS ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.</p>
<p>WITHOUT LIMITING THE FOREGOING, STEADY DOES NOT WARRANT THAT ACCESS TO THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS, IF ANY, WILL BE CORRECTED. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE AND YOUR RELIANCE UPON ANY OF ITS CONTENTS IS AT YOUR SOLE RISK.</p>
<p>YOU SHALL BE SOLELY AND FULLY RESPONSIBLE FOR ANY DAMAGE TO THE SERVICE OR ANY COMPUTER SYSTEM, ANY LOSS OF DATA, OR ANY IMPROPER USE OR DISCLOSURE OF INFORMATION ON THE SERVICE CAUSED BY YOU OR ANY PERSON USING YOUR USERNAME OR PASSWORD. STEADY CANNOT AND DOES NOT ASSUME ANY RESPONSIBILITY FOR ANY LOSS, DAMAGES OR LIABILITIES ARISING FROM THE FAILURE OF ANY TELECOMMUNICATIONS INFRASTRUCTURE, OR THE INTERNET, OR FOR YOUR MISUSE OF ANY PROTECTED HEALTH INFORMATION, ADVICE, IDEAS, INFORMATION, INSTRUCTIONS OR GUIDELINES ACCESSED THROUGH THE SERVICE.</p>
<h2>Limitations of Liability</h2>
<p>IN THE EVENT OF ANY PROBLEM WITH THE SERVICE OR ANY OF ITS CONTENT, YOU AGREE THAT YOUR SOLE REMEDY IS TO CEASE USING THE SERVICE. UNDER NO CIRCUMSTANCES SHALL STEADY, ANY STEADY LICENSOR OR SUPPLIER, OR ANY THIRD PARTY WHO PROMOTES THE SERVICE OR PROVIDES YOU WITH A LINK TO THE SERVICE BE LIABLE IN ANY WAY FOR YOUR USE OF THE SERVICE OR ANY OF ITS CONTENT, INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR OMISSIONS IN ANYCONTENT, ANY INFRINGEMENT BY ANY CONTENT OF THE INTELLECTUAL PROPERTY RIGHTS OR OTHER RIGHTS OF THIRD PARTIES, OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT.</p>
<p>UNDER NO CIRCUMSTANCES SHALL STEADY, ITS LICENSORS OR SUPPLIERS OF NON-MEDICAL/ MENTAL HEALTHCARE SERVICES OR ANY THIRD PARTY WHO PROMOTES THE SERVICE OR PROVIDES YOU WITH A LINK TO THE SERVICE, BE LIABLE FOR ANY PUNITIVE, EXEMPLARY, CONSEQUENTIAL, INCIDENTAL, INDIRECT OR SPECIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY PERSONAL INJURY, LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR COMPUTER OR OTHERWISE) ARISING FROM OR IN CONNECTION WITH YOUR USE OF THE SERVICE, WHETHER UNDER A THEORY OF BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY, MALPRACTICE OR OTHERWISE, EVEN IF STEADY OR THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
<p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.</p>
<h2>Hyperlinks</h2>
<p>Any link (including a hyperlink, button or referral device of any kind) used in the Service is provided for your use and convenience. The appearance of a link does not constitute an endorsement, recommendation or certification by Steady, nor should the presence of a link in any way be construed as a suggestion that any third party website has any relationship to Steady. Steady does not endorse the content on any third-party websites. Steady is not responsible for the content of linked third-party websites or third-party advertisements, and does not make any representations regarding its content or accuracy. Steady does not knowingly link to websites that may infringe on valid and existing trademarks, service marks, copyrights or patents. Your use of third-party websites is at your own risk and subject to the terms and conditions of use for such websites.</p>
<h2>Termination</h2>
<p>Steady may suspend or terminate your access to the Service at any time, for any reason or for no reason at all. Steady has the right (but not the obligation) to refuse to provide access to the Service to any person, agency or organization at any time, for any reason or for no reason at all, in our sole discretion. Steady reserves the right to change, suspend, or discontinue all or part of the Service, temporarily or permanently, without prior notice.</p>
<h2>Privacy</h2>
<p>You affirmatively consent to the fact that Steady administrators will, as needed, review your particular "Session Transcripts" or "Chats" for the following purposes:</p>
<p>&zwj;During your on-boarding and during the Therapist matching process to ascertain you are able to successfully engage with the Therapist. This access may be accomplished using a Steady on-boarding specialist and not a Therapist;</p>
<p>To review your complaint about a particular issue/instance that your report about your Therapist or about a particular language intention with your Therapist;</p>
<p>For your safety concerns or complaints of unethical Therapist practices;</p>
<p>To transition you to a new Therapist or if your account is non responsive or inactive for an extended period of time; or</p>
<p>To address raised quality assurance concern(s) that may apply to an individual Therapist, a state-wide compliance issue or a national network issue.</p>
<p>&zwj;</p>
<p>You consent to Steady using "Meta Data" and other search terms to search for trends and patterns that may affect the quality of the Services provided to you or the practices of the Therapists. If your particular complaint requires a review of Chat Transcripts in the original form, you consent to a Steady administrators accessing your account only to respond to your particular complaint or raised issue.</p>
<p>You affirmatively grant Steady permission to have your Therapist periodically provide non-content based clinical assessments of your progress to Steady. You understand that Steady provides the Therapists with clinical assessment tools that serve to provide information on your mental health and well-being; and that results can be seen by your Therapist to discuss with you.</p>
<p>All de-identified data, meta-data and research data collected by Steady through your use of the Services remains the sole property of Steady. Steady may share some or all of this research data with third parties in connection with joint or outside research, analytics, or similar purposes. Research data is used in non identifiable aggregate form, that is, as a statistical measure, but not in a manner that would identify you personally. You shall not request that Steady remove or delete any of such data.</p>
<p>You agree that the email username you provide can be used by Steady to send you marketing offers from Steady.</p>
<p>You acknowledge that Steady must store all personal data as required by law, typically for no less than seven (7) years, and may not delete such medical records data, even upon your direct request.</p>
<h2>Special Programs</h2>
<p>From time to time Steady offers free programs to its Clients. These include, but are not limited to topics related sexual assault LGBT issues, PTSD and other specific issues. These programs are limited to a certain number of individuals and chosen conditions for which you may not qualify. Your participation in these programs is voluntary and separate agreements and waiver forms may be required.</p>
<p>Steady participates in research studies with select universities. You may be offered the opportunity to participate in these studies. These studies may include separate agreement forms, waivers and confidentiality terms that you will have to review and accept separate from these Terms.</p>
<h2>Gift Cards</h2>
<p>Gift cards are available for purchase to be used solely towards the use of the Service (&ldquo;Gift Card&rdquo;). A Gift Card is not a credit or debit card and has no implied warranties. A Gift Card is not redeemable for cash unless required by law and cannot be used to make payments on any charge account. Gift Cards cannot be used to buy other Gift Cards. Gift Cards are not valid or redeemable after the expiration date associated with the Gift Card. Steady reserves the right to deactivate or reject any Gift Card issued or procured, directly or indirectly, in connection with fraudulent actions, unless prohibited by law.</p>
<p>Lost or stolen Gift Cards can only be replaced upon presentation of original sales receipt for any remaining balance. It will be void if altered or defaced.</p>
<p>Steady is not responsible for emailed Gift Cards that do not reach their intended recipient for any reason. Bounced email is not monitored.</p>
<p>STEADY MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO GIFT CARDS OR YOUR STEADY BALANCE, INCLUDING WITHOUT LIMITATION, ANY EXPRESS OR IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. IN THE EVENT A GIFT CARD IS NON-FUNCTIONAL, YOUR SOLE REMEDY, AND STEADY&rsquo;S SOLE LIABILITY, WILL BE THE REPLACEMENT OF THAT GIFT CARD. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
<h2>Disputes</h2>
<p>Any dispute or claim relating in any way to your use of the Steady Service will be resolved by binding confidential arbitration, rather than in court.</p>
<p>There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must interpret these Terms as a court would.</p>
<p>It is agreed that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. If for any reason a claim proceeds in court rather than in arbitration, Steady and you each waive any right to a jury trial. It is further agreed that you may not bring suit in court to enjoin infringement or other misuse of intellectual property rights.</p>
<h2>General</h2>
<p>These Terms are governed by the laws of the Republic of Singapore, without regard to any conflict of laws rules or principles. You agree to submit to the venue and jurisdiction of the Singapore courts in the cases that arbitration is not successful or is assigned or appealed to a civil court. Steady&rsquo;s failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. Notices required to be given to you by Steady may be delivered by electronic mail to the address provided during the creation of your Account. These Terms constitute the entire Agreement between Steady and yourself regarding the Service, and supersede and replace any prior agreements you and Steady might have regarding the Service. Steady may revise these Terms from time to time without notice to you. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. We encourage you to read the stems periodically.</p>
<p>&zwj;</p>
<p><strong>These Terms of Use were last updated on July 7, 2021 and are deemed effective as amended as of this date.</strong></p>
    </div>
  );
};

export default PrivacyPolicy;
