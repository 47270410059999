import React from "react";

import "../../../utils/layout.css";
import "../../../utils/constants.css";

const ExpiredLink = () => {

  const publicDir = process.env.PUBLIC_URL;
  const logoImagePath = publicDir + "/assets/images/logo.png";
  const waveImagePath = publicDir + "/assets/images/wave.png";

  return (
    <div className="fullscreen flex-column-start-center">
      <img className="logo-image" src={logoImagePath} />
      <div className='primary-font' style={{ fontSize: 25, padding: 10, textAlign: 'justify', }} >The link has expired. Please request for a new one via the app.</div>
      <img className="wave-image" src={waveImagePath} />
    </div>
  );
};

export default ExpiredLink;
